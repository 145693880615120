<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-table
      :items="customers"
      :fields="fields"
      responsive
    >
      <template #cell(name)="data">
        <router-link
          :to="{ name: 'projects', params: { customerId: data.item.id } }"
          class="text-primary font-weight-bolder"
        >
          {{ data.value }}
        </router-link>
      </template>

      <template #cell(email)="data">
        <div>
          {{ data.value }}
        </div>
      </template>
      <template #cell(created_at)="data">
        <div>
          {{ data.value | date }}
        </div>
      </template>

      <template #cell(tools)="data">
        <b-dropdown
          id="dropdown-1"
          size="sm"
          dropright
          no-caret
          variant="outline-none"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              color="#000"
            />
          </template>
          <!-- <b-dropdown-item @click="deactivateCustomer(data.item.id)">{{
            $t('deactivate')
          }}</b-dropdown-item> -->
          <b-dropdown-item
            v-b-modal.confirmDelete
            variant="danger"
            @click="customerToDeleteId = data.item.id"
          >{{ $t('delete') }}</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <confirmation-modal
      :id="`confirmDelete`"
      :variant="'danger'"
      @confirm="removeCustomer()"
    >
      <template v-slot:content>
        {{ $t('deleteCustomerConfirmation') }}
      </template>
    </confirmation-modal>
    <b-pagination
      class="mt-1 ml-1"
      :value="pagination.page"
      :total-rows="pagination.total_objects"
      :per-page="pagination.per_page"
      @change="handlePageChange($event)"
    />
  </b-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: { ConfirmationModal },
  props: {
    customers: {
      type: Array,
      default() {
        return []
      },
    },
    pagination: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('name'),
        },
        {
          key: 'email',
          label: this.$t('email'),
        },
        {
          key: 'phone',
          label: this.$t('phone'),
        },
        {
          key: 'created_at',
          label: this.$t('createdAt'),
        },
        {
          key: 'tools',
          label: this.$t('actions'),
        },
      ],
      customerToDeleteId: null,
    }
  },

  methods: {
    ...mapMutations('customers', {
      changePage: 'CHANGE_PAGE',
    }),
    ...mapActions('customers', ['deleteCustomer', 'fetchCustomers']),
    deactivateCustomer() {},
    async removeCustomer() {
      await this.deleteCustomer(this.customerToDeleteId)
      await this.fetchCustomers({})
    },
    handlePageChange(page) {
      this.loading = true
      this.changePage(page)
      this.fetchCustomers({ pagination: this.pagination }).then(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
