<template>
  <div>
    <b-row>
      <b-col
        sm="12"
        class="d-flex justify-content-between pb-0 align-items-center"
      >
        <h1 class="font-weight-bolder">
          {{ $t('customers') }}
        </h1>
        <b-button
          :to="{ name: 'new-customer' }"
          variant="primary"
        >
          {{ $t('create', { type: $t('customer') }) }}
        </b-button>
      </b-col>
      <b-col
        sm="12"
        md="6"
        lg="3"
        class="mb-1"
      >
        <b-form-group
          class="mr-1 mb-0 w-100"
        >
          <b-form-input v-model="customerQuery" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <customers-table
          :customers="customers"
          :pagination="pagination"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import _ from 'underscore'
import CustomersTable from '@/components/customers/CustomersTable.vue'

export default {
  components: {
    CustomersTable,
  },
  data() {
    return {
      customerQuery: '',
    }
  },
  computed: {
    ...mapGetters('customers', ['customers', 'pagination']),
  },

  watch: {
    customerQuery: _.debounce(function search(query) {
      if (query.length === 0) {
        this.fetchCustomers({})
        return
      }
      this.fetchCustomers({
        byName: query,
        meta: {
          pagination: {
            per_page: 10,
          },
        },
      })
      // this.loading = false
    }, 500),
  },

  created() {
    this.clearProject({})
    this.fetchCustomers({})
  },

  methods: {
    ...mapActions('customers', ['fetchCustomers']),
    ...mapMutations('projects', {
      clearProject: 'SET_PROJECT',
    }),
  },
}
</script>

<style></style>
